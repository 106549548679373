export class PasswordHelper {
  constructor(http, store = null) {
    this.http = http;
    this.store = store;
  }

  async sentMail(email, languageIsoCode) {
    var FormData = require("form-data");
    var formData = new FormData();

    this.store.commit("password/setEmail", email);

    formData.append("Email", email);
    formData.append("LanguageIsoCode", languageIsoCode);
    try {
      const res = await this.http.API.post(
        "customer/password/forget",
        formData
      );
      return res;
    } catch (error) {
      return error.response;
    }
  }

  async resetPassword(token, email, pass, languageIsoCode) {
    var FormData = require("form-data");
    var formData = new FormData();

    formData.append("Email", email);
    formData.append("Token", token);
    formData.append("Password", pass);
    formData.append("LanguageIsoCode", languageIsoCode);

    try {
      const response = await this.http.API.post(
        "customer/password/reset",
        formData
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected status code: ${response.status}`);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async checkTokenAndEmail(query) {
    const FormData = require("form-data");
    const data = new FormData();
    data.append("Email", query.email);
    data.append("Token", query.token);

    try {
      const response = await this.http.API.post(
        "customer/password/token",
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  }
}
